import { Navbar } from './components/navbar/Navbar';
import { Frontpage } from './components/frontpage/Frontpage';
import { Authors } from './components/authors/Authors';
import { Explanation } from './components/explanation/Explanation';
import { Reasons } from './components/reasons/Reasons';
import { Why } from './components/why/Why';
import { We } from './components/we/We';
import { Path } from './components/path/Path';
import { Newsletter } from './components/newsletter/Newsletter';
import { Modules } from './components/modules/Modules';
import { Talk } from './components/talk/Talk';
import { Footer } from './components/footer/Footer';

import './App.css';


function App() {
  return (
    <div className="App">
      <Navbar />
      <Frontpage />
      <Authors />
      <Explanation />
      <Reasons />
      <Newsletter />
      <Why />
      <We />
      <Path />
      <Modules />
      <Talk />
      <Footer />
    </div>
  );
}

export default App;

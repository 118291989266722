import React from 'react';

import './Reasons.css';

export const Reasons = () => {
    return (
        <div className='rea-container' id='reasons'>
            <div className='container'>
                <div className='inner-container'>
                    <div className='row rea-title' >
                        <span>Oh! solo para creativas?</span>
                    </div>
                    <div className='row rea-boxes-container'>
                        <div className='col-md-4 rea-boxes-mobile'>
                            <div className='rea-box'>
                                <p className='rea-box-title'>Razón 1</p>
                                <p className='rea-box-text'>Porque para llegar lejos como creativas no solo hace falta tener talento: también hace falta tener seguridad, autoconfianza, presencia y actitud. Y eso se puede trabajar.</p>
                            </div>
                        </div>
                        <div className='col-md-4 rea-boxes-mobile'>
                            <div className='rea-box'>
                                <p className='rea-box-title'>Razón 2</p>
                                <p className='rea-box-text'>Porque la creatividad es arriesgarnos, mostrarnos, defender nuestras ideas y equivocarnos, y eso a muchas no nos lo han enseñado.</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='rea-box'>
                                <p className='rea-box-title'>Razón 3</p>
                                <p className='rea-box-text'>Porque hemos pasado por lo mismo que están pasando miles de creativas, y tenemos bien localizados los frenos internos y los techos externos, y queremos ayudar a romperlos.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

import React, { useState } from 'react';

import './Newsletter.css';

export const Newsletter = () => {

    return (
        <div className='news-container'>
            <div className='container'>
                <div className='inner-container' >
                    <div style={{ textAlign: 'center' }}>
                        <span className='news-text'>NEWSLETTER</span>
                    </div>
                    <div className='row' style={{ marginTop: '24px' }}>
                        <div className='col-md-6'><p>Sigue de cerca el movimiento revolucionario OH! SISTER y recibe 1 email/mes hablándote de lo que no se suele hablar en publicidad.</p></div>
                        <div className='col-md-6'>
                            <a href='http://eepurl.com/idek6z' className="btn btn-primary news-button">Quiero suscribirme!</a> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

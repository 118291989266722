import React from 'react';

import './Modules.css';

export const Modules = () => {
    const mod1 = require('../../assets/modules/mod-1.png');
    const mod2 = require('../../assets/modules/mod-2.png');
    const mod3 = require('../../assets/modules/mod-3.png');
    const mod4 = require('../../assets/modules/mod-4.png');
    const mod5 = require('../../assets/modules/mod-5.png');
    const mod6 = require('../../assets/modules/mod-6.png');
    const mod7 = require('../../assets/modules/mod-7.png');
    const mod8 = require('../../assets/modules/mod-8.png');
    const mod9 = require('../../assets/modules/mod-9.png');
    const mod10 = require('../../assets/modules/mod-10.png');

    return (
        <div className='modules-background'>
            <div className='container modules-container'>
                <div className='inner-container'>
                    <div className='row modules-title'>
                        <h1>MÓDULOS</h1>
                    </div>
                    <div className='row modules-row'>
                        <div className='col-md-1 mod-image'>
                            <img src={mod1} width='50px' alt='shape' />
                        </div>
                        <div className='col-md-5 mod-image-mobile'>
                            <p className='mod-text-title'>1. Presentación</p>
                            <p className='body-text'>Por qué existimos, quiénes somos, funcionamiento del curso, compromisos de grupo, qué esperas obtener del curso.</p>
                        </div>
                        <div className='col-md-1 mod-image'>
                            <img src={mod2} width='50px' alt='shape' />
                        </div>
                        <div className='col-md-5 mod-image-mobile'>
                            <p className='mod-text-title'>2. Bloqueos</p>
                            <p className='body-text'>Hablemos del miedo, la culpa, las inseguridades intrínsecas de los procesos creativos... y aprendamos a gestionarlas mejor.</p>
                        </div>
                    </div>
                    <div className='row modules-row'>
                        <div className='col-md-1 mod-image'>
                            <img src={mod3} width='50px' alt='shape' />
                        </div>
                        <div className='col-md-5 mod-image-mobile'>
                            <p className='mod-text-title'>3. Tu campaña + importante</p>
                            <p className='body-text'>La campaña más importante que tendrás entre manos no es la de esa marca supertop sino la tuya. Cómo y por qué hacernos visibles, fichas técnicas, ...</p>
                        </div>
                        <div className='col-md-1 mod-image'>
                            <img src={mod4} width='50px' alt='shape' />
                        </div>
                        <div className='col-md-5 mod-image-mobile'>
                            <p className='mod-text-title'>4: La otra creatividad 1</p>
                            <p className='body-text'>Recursos creativos, nuevas narrativas, creatividad inclusiva.</p>
                        </div>
                    </div>
                    <div className='row modules-row'>
                        <div className='col-md-1 mod-image'>
                            <img src={mod5} width='50px' alt='shape' />
                        </div>
                        <div className='col-md-5 mod-image-mobile'>
                            <p className='mod-text-title'>5. La otra creatividad 2</p>
                            <p className='body-text'>Creatividad con nuevas referentes. Encontremos la tuya, diversa, heteorgénea, única.</p>
                        </div>
                        <div className='col-md-1 mod-image'>
                            <img src={mod6} width='50px' alt='shape' />
                        </div>
                        <div className='col-md-5 mod-image-mobile'>
                            <p className='mod-text-title'>6. Ser y estar</p>
                            <p className='body-text'>Veamos dónde nos colocamos: cómo nuestra actitud, forma de comunicarnos y relacionarnos, condiciona nuestro crecimiento profesional.</p>
                        </div>
                    </div>
                    <div className='row modules-row'>
                        <div className='col-md-1 mod-image'>
                            <img src={mod7} width='50px' alt='shape' />
                        </div>
                        <div className='col-md-5 mod-image-mobile'>
                            <p className='mod-text-title'>7. Master Class Invitadas Especiales</p>
                            <p className='body-text'>Todas las ediciones contaremos con la participación de varias profesionales tronchas-tronchas que compartirán con nosotras su experiencia y mirada.</p>
                        </div>
                        <div className='col-md-1 mod-image'>
                            <img src={mod8} width='50px' alt='shape' />
                        </div>
                        <div className='col-md-5 mod-image-mobile'>
                            <p className='mod-text-title'>8. Hay más de una salida</p>
                            <p className='body-text'>Tranquila, trabajar de creativa no solo consiste en trabajar en una agencia de publicidad como creativa, hay futuro más allá de una agencia.</p>
                        </div>
                    </div>
                    <div className='row modules-row'>
                        <div className='col-md-1 mod-image'>
                            <img src={mod9} width='50px' alt='shape' />
                        </div>
                        <div className='col-md-5 mod-image-mobile'>
                            <p className='mod-text-title'>9. Redes de mujeres creativas</p>
                            <p className='body-text'>¿Por qué es importante estar en una red de mujeres creativas?, ¿Qué me aporta sumar a la comunidad?, ¿Cuáles son?</p>
                        </div>
                        <div className='col-md-1 mod-image'>
                            <img src={mod10} width='50px' alt='shape' />
                        </div>
                        <div className='col-md-5 mod-image-mobile'>
                            <p className='mod-text-title'>10. Cierre/ despedida</p>
                            <p className='body-text'>Revisión de objetivos, puesta en común y despedida...¡o hasta pronto!.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react';

import './Why.css';

export const Why = () => {
    const whyImage = require('../../assets/why/why.png');


    return (
        <div className='why-container'>
            <div className='container'>
                <div className='inner-container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='why-title-container'>
                                <p className='why-subtitle'>Vale muy bien pero...</p>
                                <h1>PARA QUÉ ME VA A SERVIR?</h1>
                            </div>
                            <div className='why-box'>
                                <span className='subtitle'>1. Para crear con más libertad y disfrute. </span>
                                <br />
                                <span className='body-text'>Nuestra profesión mola, pero para disfrutarla hay que cambiar la forma de enfocarla y de gestionar nuestros miedos e inseguridades, que por cierto, son universales, da igual tus años de experiencia o si te llamas Mónica o Eva. Es así.</span>
                            </div>
                            <div className='why-box'>
                                <span className='subtitle'>2. Para ser mejor creativa. </span>
                                <br />
                                <span className='body-text'>Herramientas útiles, recursos creativos y todos los tips y trucos que usamos las directoras creativas en activo en nuestro día a día.</span>
                            </div>
                            <div className='why-box'>
                                <span className='subtitle'>3. Para detectar tu creatividad y potenciarla. </span>
                                <br />
                                <span className='body-text'>No te pierdas intentando imitar un estilo creativo "que mola". Tu creatividad es única, vamos a detectarla y potenciarla.</span>
                            </div>
                            <div className='why-box'>
                                <span className='subtitle'>4. Para ocupar tu lugar en el trabajo. </span>
                                <br />
                                <span className='body-text'>Revisemos formas de pensar, y de estar, son clave, así como cómo presentar una campaña, cómo estar frente a un cliente, en las redes…</span>
                            </div>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-5'>
                            <div className='why-box'>
                            <img src={whyImage} width='85%' alt='ohsister'/>
                            </div>
                            <div className='why-box'>
                                <span className='subtitle'>5. Para estar actualizada profesionalmente. </span>
                                <br />
                                <span className='body-text'>Actualizarse o aburrir. Llegan nuevas narrativas, nuevas perspectivas. Rompamos con los roles y diálogos de siempre.</span>
                            </div>
                            <div className='why-box'>
                                <span className='subtitle'>6. Para ver futuro más allá de una agencia. </span>
                                <br />
                                <span className='body-text'>Qué salidas profesionales tienes como creativa publicitaria. Montar tu propia agencia, crear tu propia marca, o qué.</span>
                            </div>
                            <div className='why-box'>
                                <span className='subtitle'>7. Para tejer una red profesional. </span>
                                <br />
                                <span className='body-text'>Una red profesional maja-maja y potente-potente en la que apoyarte, compartir, crecer y hacerte fuerte.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

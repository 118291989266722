import React, { useState } from 'react';
import { send } from 'emailjs-com';

import './Talk.css';

export const Talk = () => {

    const [toSend, setToSend] = useState({
        from_name: '',
        to_name: 'hola@oh-sister.com',
        message: '',
        reply_to: '',
    });

    const [sending, setSending] = useState(false);

    const [sendButton, setSendButton] = useState('Enviar');

    const onSubmit = (e) => {
        e.preventDefault();
        setSending(true);
        send(
            'service_47b6eu9',
            'template_xpn2j7c',
            toSend,
            'Em6rrCAxL0bBSbbLg'
        )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setSending(false);
                setSendButton('Enviado! :-)');
            })
            .catch((err) => {
                console.log('FAILED...', err);
                setSending(false);
                setSendButton('Error... :-(');
            });
    };

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    return (
        <div className='talk-background' id='talk'>
            <div className='container talk-container'>
                <div className='inner-container'>
                    <div className='row talk-title'>
                        <h1>HABLAMOS?</h1>
                    </div>
                    <div className='row'>
                        <div className='col-md-5 col-lg-5 col-xl-4'>
                            <h2>Tenemos ganas de conocerte :)</h2>
                            <p className='body-text' style={{ marginTop: '1em' }}>Tanto si estás decidida a apuntarte como si aún no lo estás, escríbenos. Te daremos más info sobre grupos, horarios, precio... También puedes seguir todo lo que hacemos en linkedin.</p>
                            <div style={{ display: 'flex', marginBottom: '60px' }}>
                                <a className='talk-link' href='mailto:hola@oh-sister.com'>
                                    <i className="fa fa-regular fa-envelope fa-2x talk-item"></i>
                                </a>
                                <a className='talk-link' href='https://www.linkedin.com/company/ohsister/'>
                                    <i className="fa fa-brands fa-linkedin fa-2x talk-item"></i>
                                </a>
                            </div>
                        </div>
                        <div className='col-md-auto col-lg-auto col-xl-2'></div>
                        <div className='col-md-6'>
                            <form onSubmit={onSubmit}>
                                <div className='form-item'>
                                    <input type="text"
                                        className="form-control input-box"
                                        aria-describedby="name "
                                        placeholder="Nombre"
                                        name="from_name"
                                        autoComplete="off"
                                        value={toSend.from_name}
                                        onChange={handleChange} />
                                    {/* onFocus={() => setFormState({ ...formState, validEmail: true })} */}
                                    {/* onKeyDown={handleKeyDown} /> */}

                                    <input type="email"
                                        className="form-control input-box"
                                        aria-describedby="email"
                                        placeholder="Email"
                                        name="reply_to"
                                        autoComplete="off"
                                        value={toSend.reply_to}
                                        onChange={handleChange} />

                                    <textarea type="text"
                                        className="form-control input-box input-description"
                                        aria-describedby="description"
                                        placeholder="Escríbenos aquí para solicitar más info y poder matricularte..."
                                        name="message"
                                        autoComplete="off"
                                        value={toSend.message}
                                        onChange={handleChange} />
                                </div>
                                <div className='col-md-6'>
                                    
                                    <button type="submit" className="btn btn-primary email-button">
                                        {sending && (<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>)}
                                        {!sending && (<span>{sendButton}</span>)}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react';

import './We.css';

export const We = () => {
    const cristina = require('../../assets/we/cristina.png');
    const iria = require('../../assets/we/iria.png');

    return (
        <div className='we-container'>
            <div className='container'>
                <div className='inner-container'>
                    <div className='row'>
                        <div className='col-md-5 order-2 order-md-1' >
                            <div className='row we-row'>
                                <img src={cristina} style={{ width: '60%', margin: 'auto' }} alt='Cristina'/>
                            </div>
                            <div className='we-row'>
                                <span className='subtitle' style={{ color: 'white' }}>Cristina Guezuraga</span>
                                <p className='body-text' style={{ color: 'white' }}>Directora Creativa en <a href="https://sirope.es/">Sirope</a>.</p>
                                <p className='body-text' style={{ color: 'white' }}>Podría decirte que soy una de las creativas publicitarias más virales y cañeras del país, y que llevo 22 años haciendo campañas para grandes marcas (sí, soy de Bilbao).</p>
                                <p className='body-text' style={{ color: 'white' }}>Pero ¿quién soy también? Soy una creativa que tardó años en creer que era creativa. También tardé años en atreverme a presentar mis campañas; en ocupar mi sitio en la agencia; en darme cuenta de cómo me frenaban mis miedos; en darme cuenta de cómo hablaba en las reuniones, con mis compañeros, con los clientes…</p>
                                <p className='body-text' style={{ color: 'white' }}>Y como ya he pasado por todo eso, y sé más o menos cómo salir de ahí, si quieres te acompaño a ti en tu proceso. Te aviso que va a ser esclarecedor, liberador, y también divertido. Aquí, <a href='https://www.linkedin.com/in/cristinaguezuraga/'>mi linkedin</a>.</p>
                            </div>
                        </div>
                        <div className='col-md-2 order-1 order-md-2' style={{ textAlign: 'center' }}>
                            <h1 style={{ color: 'white', marginBottom: '60px' }}>QUIÉNES SOMOS?</h1>
                        </div>
                        <div className='col-md-5 order-3 order-md-3' >
                            <hr className='we-line-separator-mobile'/>
                            <div className='row we-row'>
                                <img src={iria} style={{ width: '60%', margin: 'auto' }} alt='Iria'/>
                            </div>
                            <div className='we-row'>
                                <span className='subtitle' style={{ color: 'white' }}>Iria Otero</span>
                                <p className='body-text' style={{ color: 'white' }}>Directora Creativa en <a href='https://peakybranders.es'>Las Peaky Branders</a> y creadora del proyecto <a href="https://fillingthegap.org/">Filling The Gap</a>.</p>
                                <p className='body-text' style={{ color: 'white' }}>En mis 14 años de experiencia como creativa publicitaria perdí la cuenta de las veces que pensé en cambiar de profesión.</p>
                                <p className='body-text' style={{ color: 'white' }}>Hasta que empecé a rodearme de otras mujeres creativas, compartir los mismos problemas, el mismo desapego con el tipo de publicidad que hacíamos... y me di cuenta de lo necesarias que son nuestras narrativas, perspectivas y aptitudes para transformar la industria publicitaria (por dentro y por fuera). He vuelto a creer en lo que hago y a disfrutar haciéndolo, que no es poco.</p>
                                <p className='body-text' style={{ color: 'white' }}>En <a href='https://www.linkedin.com/in/creativa-publicitaria-fillingthegap-iria-otero/'>mi linkedin</a> puedes curiosear un poquito más ;)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react';
import Cookies from '../cookies/Cookies';
import PrivacyPolicy from '../privacyPolicy/PrivacyPolicy';
import TermsAndConditions from '../termsAndConditions/TermsAndConditions';

import './Footer.css';

export const Footer = () => {
    return (
        <div className='footer'>
            <TermsAndConditions />
            <PrivacyPolicy />
            <Cookies />
            <div className='footer-container'>
                <div className='footer-text'>
                    <span className='footer-link' data-bs-toggle="modal" data-bs-target="#termsAndConditionsModal">Términos Y Condiciones</span>
                    <span className='footer-no-mobile'> | </span>
                    <span className='footer-link' data-bs-toggle="modal" data-bs-target="#privacyPolicyModal">Política De Privacidad</span>
                    <span className='footer-no-mobile'> | </span>
                    <span className='footer-link' data-bs-toggle="modal" data-bs-target="#cookiesModal">Cookies</span>
                </div>
                <div className='footer-text'>
                    <span>© OhSister 2023</span>
                    {/* <span>© 2022 Oh! Sister &#60;Carlos Lamosa&#92;&#62;</span> */}
                </div>
            </div>
        </div>
    )
}
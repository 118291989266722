import React from 'react';

import './Authors.css';

export const Authors = () => {
  const fillingTheGap_logo = require('../../assets/authors/fillingTheGap_logo.png');
  const aceleradoraCreativas_logo = require('../../assets/authors/aceleradoraCreativas_logo.png');
  const peaky_logo = require('../../assets/authors/peaky_logo.png');


  return (
    <div className='authors-container'>
      <div className='container'>
        <div className='inner-container'>
          <div className='row row-margin-mobile'>
            <span className='authors-text'>Un proyecto de:</span>
          </div>
          <div className='row logo-container'>
            <div className='col-md-4 authors-logo row-margin-mobile' >
              <img src={aceleradoraCreativas_logo} width='200px' alt='Aceleradora de Creativas logo'/>
            </div>
            <div className='col-md-4 authors-logo' >
              <img src={fillingTheGap_logo} width='94px' alt='FillingTheGap logo'/>
            </div>
            <div className='col-md-4 authors-logo'>
              <img src={peaky_logo} width='120px' alt='Peaky Branders logo'/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

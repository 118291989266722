import React, { useRef } from 'react';

import './Navbar.css';

export const Navbar = () => {
    const logo = require('../../assets/logo.png');

    let collsapseButton = useRef(null);

    const handleClick = () => {
        if (collsapseButton) {
            collsapseButton.current.click();
        }
    }

    return (
        <div>
            <div className="navbar navbar-expand-lg navbar-os sfixed-top">
                <div className="container">
                    <a className="navbar-brand" href="https://oh-sister.com">
                        <img src={logo} alt="Logo" width="122px"/>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Oh! Sister</h5>
                            <button ref={collsapseButton} type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                <li className="nav-item">
                                    <a className="nav-link navbar-os-text" href="#explanation" onClick={handleClick}>Sister, qué es esto?</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link navbar-os-text" href="#reasons" onClick={handleClick}>Para quién es?</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link navbar-os-text" href="#talk" onClick={handleClick}>Hablamos?</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
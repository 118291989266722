import React from 'react';

import './Path.css';

export const Path = () => {

    // const rocioRestaino = require('../../assets/path/rocioRestainoImage.png');

    return (
        <div className='pack-container'>
            <div className='container'>
                <div className='row pack-title'>
                    {/* <h1>CURSO <br className='mobile'/>ONLINE <br className='mobile'/>2022-23</h1> */}
                    <h1>3ª edición - <br className='mobile'/>Curso Online <br className='mobile'/>en Directo</h1>
                </div>
                <div className='row pack-boxes-container'>
                    <div className='col-md-4 pack-col'>
                        <div className='pack-box'>
                            <p className='subtitle'>Grupos reducidos</p>
                            <p className='body-text'>Crearemos grupos de máximo 10 creativas. Escríbenos rápido para no quedarte sin plaza ;)</p>
                        </div>
                    </div>
                    <div className='col-md-4 pack-col'>
                        <div className='pack-box'>
                            <p className='subtitle'>20 horas de formación</p>
                            <p className='body-text'>Sesiones semanales en directo, todas online, con dinámicas de grupo prácticas para favorecer el aprendizaje y la interacción.</p>
                        </div>
                    </div>
                    <div className='col-md-4 pack-col'>
                        <div className='pack-box image-relative-container'>
                            <p className='subtitle'>Colaboradoras TOP</p>
                            <p className='body-text'>Judith Francisco, Rocío Restaino, Ena Cardenal, Yolanda Dominguez… <b>y esta edición ¡Elvira Lindo y Las Feas!</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

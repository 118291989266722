import React from 'react';

import './Explanation.css';

export const Explanation = () => {
    const simpleArrow = require('../../assets/explanation/simpleArrow.png');
    const pinkShape = require('../../assets/explanation/pinkShape.png');
    const blackShape = require('../../assets/explanation/blackShape.png');
    const yellowShape = require('../../assets/explanation/yellowShape.png');
    const ohSisterResume = require('../../assets/explanation/ohSisterResume.png');

    return (
        <div className='exp-background' id='explanation'>
            <div className='container'>
                <div className='inner-container'>
                    <div className='row'>
                        <div className='col-md-1 exp-image'>
                            <img src={simpleArrow} width='50px' alt='shape'/>
                        </div>
                        <div className='col-md-5 exp-image-mobile'>
                            <p className='exp-text-title'>La primera formación</p>
                            <p className='exp-text-paragraph'>Pues eso, la 1ª. No hay otra igual, ni en contenido ni en forma, y es la única dirigida en exclusiva a creativas.</p>
                        </div>
                        <div className='col-md-1 exp-image'>
                            <img src={pinkShape} width='50px' alt='shape'/>
                        </div>
                        <div className='col-md-5 exp-image-mobile'>
                            <p className='exp-text-title'>Integral</p>
                            <p className='exp-text-paragraph'>Trabaja todos los aspectos que consolidan el desarrollo profesional de una creativa: recursos creativos, autoconfianza, seguridad, herramientas para el día a día...</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-1 exp-image'>
                            <img src={blackShape} width='50px' alt='shape'/>
                        </div>
                        <div className='col-md-5 exp-image-mobile'>
                            <p className='exp-text-title'>Impartida por creativas</p>
                            <p className='exp-text-paragraph'>Coordinada por 2 directoras creativas potentorras del panorama publicitario español (Filling The Gap y La Aceleradora de Creativas).</p>
                        </div>
                        <div className='col-md-1 exp-image'>
                            <img src={yellowShape} width='50px' alt='shape'/>
                        </div>
                        <div className='col-md-5 exp-image-mobile'>
                            <p className='exp-text-title'>Para creativas</p>
                            <p className='exp-text-paragraph'>Para creativas publicitarias que quieren acelerar sus carreras, crecer, salirse del briefing, cuestionárselo todo  y hacer profesionalmente lo que les dé la gana.</p>
                        </div>
                    </div>
                </div>
                <div className='row exp-resume-container'>
                    <div className='col-md-7 exp-ohsister-container'>
                        <img src={ohSisterResume} className='exp-ohsister-image' alt='shape'/>
                    </div>
                    <div className='col-md-1'></div>
                    <div className='col-md-4 exp-resume-container-text'>
                        <p className='exp-resume-title'>EN RESUMEN</p>
                        <p className='exp-text-paragraph'>Una forma de mirar, de pensar y de profundizar, un espacio revolucionario y colectivo en elque replantearnos TODO.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
